@import "../mixins";

/**
 * Common styles
 */

// Icons
app-icon-button {
  --padding-start: 1rem;
  --padding-end: 1rem;
  --padding-top: .5rem;
  --padding-bottom: .5rem;
  margin: 4px;
  display: inline-block;
}

// Tooltip
.app-tooltip {
  position: relative;

  .app-tooltip-element {
    background-color: rgba(var(--ion-color-dark-rgb), .7);
    color: #FFFFFF;
    padding: .3rem;
    border-radius: 5px;
    font-size: 1rem;
    position: absolute;
    bottom: -1.5rem;
    left: auto;
    z-index: 100;
    transition: opacity ease-in-out .4s;
    opacity: 0;
  }

  &:hover .app-tooltip-element {
    opacity: 1;
  }
}


// Popups
.popup-message {
  --max-width: 400px;

  .alert-message {
    font-size: 1rem;
    text-align: center;
    color: var(--ion-color-dark);

    ion-icon {
      font-size: 3rem;
    }
  }
}

.sc-ion-alert-md-h {
  --max-width: 80%;

  @include breakpoint-min(sm) {
    --max-width: 450px;
  }
}


.popup-error {
  .alert-message {
    ion-icon {
      color: #f04141;
    }
  }
}

.popup-success {
  .alert-message {
    ion-icon {
      color: #10dc60;
    }
  }
}

.popup-warning {
  .alert-message {
    ion-icon {
      color: #ffce00;
    }
  }
}

.popup-info {
  .alert-message {
    ion-icon {
      color: #3880ff;
    }
  }
}

// Flash Toasts
.flash-message {
  font-size: var(--size-16);
  text-align: center;
  --color: var(--ion-color-primary-contrast);
  --background: var(--ion-color-primary);
  @include use-font("esbuild");
}

.flash-error, .flash-success, .flash-warning, .flash-info {
  --border-style: solid;
  --border-color: transparent;
}

.flash-error {
  --background: var(--ion-color-danger-tint);
  --color: var(--ion-color-danger-contrast);
}

.flash-success {
}

.flash-warning {
  --background: var(--ion-color-warning-tint);
  --color: var(--ion-color-warning-contrast);
}


.network-status-toast {
  pointer-events: none;

  &::part(backdrop) {
    height: 0;
    width: 0;
  }

  &::part(content) {
    --overflow: visible;
    background: transparent;
    pointer-events: none;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: none;
  }
}

// Modals
.cgu-agreement-modal {
  @include breakpoint-min(md) {
    --width: 100%;
    --height: 100%;
    --max-height: calc(100% - 2rem);
    --max-width: calc(100% - 2rem);
  }
}

.medias-viewer-modal {
  --border-radius: 0 !important;
}

.default-modals {
  &::part(content) {
    box-shadow: 0 0 10px 0 var(--ion-color-medium);
  }
}

.fullscreen-modal {
  --height: 100%;
  --width: 100%;
  --max-height: 100%;
  --max-width: 100%;
}

@mixin overlay-modal-global-style() {
  --background: transparent;
  --box-shadow: none;
  --height: 100%;
  --width: 100%;
  --border-radius: 0 !important;
  --ion-backdrop-color: var(--ion-color-foreground);
  --backdrop-opacity: .2;

  &::part(content) {
    --box-shadow: none;
    pointer-events: none;
  }
}

.overlay-modal {
  @include overlay-modal-global-style();

  &.loading-modal {
    &::part(backdrop) {
      opacity: .15;
    }

    app-loading-modal {
      display: flex;
      align-items: center;
      justify-content: center;

      .container {
        display: block;
        position: relative;
        left: unset;
        bottom: unset;
        box-shadow: 0 0 20px rgba(var(--ion-color-foreground-rgb), 0.4);
      }
    }
  }
}

@include breakpoint-min(md) {
  .sheet-overlay-modal {
    @include overlay-modal-global-style();
  }
}

.large-modal {
  --max-width: 1000px;
  --width: 100%;
  --max-height: 90vh;
  --height: 100%;

  @media (max-width: 1000px) {
    --border-radius: 0 !important;
    --max-height: 100%;
    --max-width: 100%;
  }
}

.medium-modal {
  --max-width: 700px;
  --width: 100%;
  --max-height: 75vh;
  --height: 100%;

  @media (max-width: 700px) {
    --border-radius: 0 !important;
    --max-height: 100%;
    --max-width: 100%;
  }
}

.feed-team-select-modal {
  --max-width: 620px;
  --width: 100%;
  --max-height: 75vh;
  --height: 100%;

  @media (max-width: 620px) {
    --border-radius: 0 !important;
    --max-height: 100%;
    --max-width: 100%;
  }
}

.sc-ion-modal-md-h {
  --box-shadow: 0 8px 48px rgba(var(--ion-color-foreground-rgb), 0.4);
}

.dashboard-challenge-add-edit-parameters-periodicity-calendar-preview-modal .cal-day-badge {
  display: none;
}

.talk-notification-toast {
  pointer-events: none;

  &::part(backdrop) {
    height: 0;
    width: 0;
  }

  &::part(content) {
    --overflow: visible;
    background: transparent;
    pointer-events: none;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: none;
  }
}

// Buttons
ion-button.std-round {
  @include standard-round-button();
}

// Icons
.stroke-icon {
  stroke: currentColor;
}

// Popovers
.platform-popover {
  .popover-content {
    overflow: hidden;
  }
}

.notifications-popover {
  --width: 350px;
}

.emoji-panel-popover {
  --width: auto;
}

.std-mat-popover {
  button {
    padding-right: 45px;
    padding-left: 30px;
    position: relative;

    mat-icon.check {
      position: absolute;
      right: 0;
      top: calc(50% - 10px);
      height: 20px;
      width: 20px;
    }
  }
}

.tag-select-popover,
.target-select-popover,
.team-feed-select-popover,
.platform-select-popover {
  button {
    padding-right: 45px;
    position: relative;

    mat-icon.check {
      position: absolute;
      right: 0;
      top: calc(50% - 10px);
      height: 20px;
      width: 20px;
    }
  }
}

.tag-select-popover ion-icon {
  position: relative;
  top: 2px;
  margin-right: .5rem;
}

.team-feed-select-popover, .platform-select-popover {
  button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.team-feed-select-popover {
  width: 100%;
  max-width: 100% !important;

  .mat-menu-content {
    width: calc(100vw - 1rem);
  }

  ion-badge {
    position: absolute;
    right: 0;
    top: calc(50% - 10px);
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    font-size: .9rem;
  }
}

.platform-select-popover {
  min-width: 300px !important;
}

.std-menu-popover,
.feed-post-menu-popover,
.comment-menu-popover {

  &.mat-menu-panel {
    max-width: 320px;
  }

  button {
    display: flex;
    align-items: center;
    padding-right: 30px;

    ion-icon {
      margin-right: 1rem;
      height: 1.3rem;
      width: 1.3rem;
      min-width: 1.3rem;
    }
  }
}

.challenge-children-menu-popover {
  button {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      margin: 0 .2rem;
      display: flex;
      align-items: center;
    }

    span.name {
      flex-grow: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    ion-icon {
      margin: 0 .5rem 0 0;
      height: 1rem;
      width: 1rem;
    }
  }
}

.color-palette-popover::part(content) {
  --width: inherit;
  --background: transparent;
  --box-shadow: none;
}

.challenges-model-groups-popover {
  --width: calc((180px * 3) + (.5rem * 4) + 10px);
  --max-height: 60vh;

  &::part(content) {
    @include standard-scrollbar();
  }
}

.overlay-tooltip {
  background-color: rgba(var(--ion-color-light-rgb), .95);
  border-radius: var(--border-radius-default, .5rem);
  padding: .8rem;
  box-shadow: 0 0 4px 0 rgba(var(--ion-color-foreground-rgb), .5);
  max-width: 400px;
  font-size: 1rem;
}

.date-input-popover {
  --width: 250px;
  --background: var(--ion-color-background);
  --box-shadow: 0 0 8px var(--ion-color-medium);

  ion-datetime {
    --background: var(--ion-color-background);
  }

  &.presentation-time {
    --width: 200px;
  }
}

// Colors & styles
.app-color-danger {
  color: var(--ion-color-danger) !important;
}

.app-color-success {
  color: var(--ion-color-success) !important;
}

.app-text-normal {
  text-transform: none !important;
  font-style: normal !important;
}

.ion-hide-lx-up {
  @include breakpoint-min(lx) {
    display: none !important;
  }
}

.ion-hide-lx-down {
  @include breakpoint-max(lx) {
    display: none !important;
  }
}

.ion-hide-xxl-up {
  @include breakpoint-min(xxl) {
    display: none !important;
  }
}

.ion-hide-xxl-down {
  @include breakpoint-max(xxl) {
    display: none !important;
  }
}

.html-b {
  font-weight: bold;
}

.html-u {
  text-decoration: underline;
}

.html-i {
  font-style: italic;
}

// Table
.table-responsive {
  overflow-x: auto;
  overflow-y: hidden;
}
