/**
 * Messages definition
 * Theming for messages
 *   - messages-user-item
 *   - messages-user-item-with-button
 */

@mixin messages-user-item() {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  border-radius: var(--border-radius-default, 1rem);
  position: relative;

  app-img-avatar {
    height: 44px;
    width: 44px;
    min-width: 44px;
    margin: 8px;
  }

  .name {
    @include use-font("esbuild", "semibold");
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }

  .right {
    padding-left: .5rem;
    flex-grow: 1;
    position: relative;
  }

  .metadata {
    font-size: 1rem;
    color: var(--ion-color-dark);
    display: flex;
    align-items: center;
    justify-content: flex-start;

    div {
      &:before {
        content: '•';
        margin: 0 .3rem;
      }

      &:first-child:before {
        content: none;
      }

      &.last-message {
        max-width: 160px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  &.selected {
    background-color: rgba(var(--ion-color-primary-rgb), .8);
    color: var(--ion-color-primary-contrast);

    .metadata {
      color: rgba(var(--ion-color-primary-contrast-rgb), .8);
    }
  }
}

@mixin messages-user-item-with-button() {
  @include messages-user-item();

  padding-right: 60px;

  .right {
    width: calc(100% - 60px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .metadata {
    font-size: .85rem;
  }

  ion-button {
    position: absolute;
    top: calc(50% - 18px);
    right: 2px;
  }
}
