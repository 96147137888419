
/*
 * Font used by App
 */

// Fonts used by App

// Provided by https://fonts.googleapis.com/css?family=Nunito
/*@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Nunito/Nunito-Regular.ttf") format("truetype");
}*/

// Provided by https://fonts.google.com/specimen/Sofia+Sans
/*@font-face {
  font-family: "SofiaSans";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/Sofia_Sans/SofiaSans-VariableFont_wght.ttf") format("truetype");
}*/

// Provided by https://fonts.google.com/specimen/Open+Sans
@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
}
/*@font-face {
  font-family: "OpenSansBold";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSansExtraBold";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/OpenSans/OpenSans-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSansSemiBold";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/OpenSans/OpenSans-SemiBold.ttf") format("truetype");
}*/

// Provided by https://extraset.ch/typefaces/build/
@font-face {
    font-family: "ESBuild";
    font-style: normal;
    font-weight: normal;
    src: url("../assets/fonts/ESBuild/ES-Build-Regular.ttf") format("truetype");
}
/*
@font-face {
  font-family: "ESBuildSemiBold";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/ESBuild/ES-Build-SemiBold.otf") format("truetype");
}
@font-face {
  font-family: "ESBuildBold";
  font-style: normal;
  font-weight: normal;
  descent: -0.25em;
  src: url("../assets/fonts/ESBuild/ES-Build-Bold.otf") format("truetype");
}
*/

