/**
 * E-Store definition
 */


@mixin e-store-prices {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-spacing-2xs, 0.5rem);

  .customer-price, .sell-price {
    display: flex;
    padding: var(--spacing-spacing-2xs, 0.5rem) var(--spacing-spacing-s, 0.75rem);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-spacing-2xs, 0.5rem);
  }

  .customer-price {
    border-radius: var(--radius-radius-m, 0.25rem);
    background: var(--ion-color-coins, #EA9D21);
    color: var(--ion-color-coins-contrast, #FFF);
  }

  .sell-price {
    border-radius: var(--radius-radius-m, 0.25rem);
    background: var(--ion-color-background, #FFF);
    color: var(--ion-color-grey-600, #406073);
    text-decoration-line: line-through;
  }
}

@mixin e-store-price(
  $color: var(--ion-color-grey-600, #406073),
  $background: var(--ion-color-grey-200, #F6FAFC)
) {
  display: flex;
  gap: var(--spacing-spacing-2xs, 0.5rem);
  padding: var(--spacing-spacing-2xs, 0.5rem) var(--spacing-spacing-s, 0.75rem);
  justify-content: flex-start;
  align-items: center;
  border-radius: var(--radius-radius-m, 0.25rem);
  background: $background;
  color: $color;
  font-family: var(--ion-font-family-esbuild), serif;
  font-weight: 800;

  span {
    // --margin-offset--
    margin-top: var(--size-4);
  }
}

@mixin e-store-quantity-message {
  position: absolute;
  text-align: left;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;

  .text {
    display: inline-block;
    padding: var(--spacing-spacing-2xs, 0.5rem) var(--spacing-spacing-s, 0.75rem);
    border-radius: var(--radius-radius-m, 0.25rem);
    background: var(--ion-color-grey-600-contrast, #FFF);
    color: var(--ion-color-grey-600, #406073);
    font-size: var(--size-12, 0.75rem);
    line-height: var(--size-16, 1rem); /* 133.333% */
    font-family: var(--ion-font-family-title), serif;

    &.danger {
      background: var(--ion-color-danger, #E65252);
      color: var(--ion-color-danger-contrast, #FFF);
    }
  }
}

@mixin e-store-sep {
  height: 0.0625rem;
  align-self: stretch;
  background: var(--ion-color-grey-300, #E6F1F5);
}

@mixin e-store-brand {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: var(--radius-radius-xl, 1rem);
  background: var(--ion-color-grey-100, #F6FAFC);
  width: 7.5rem;
  height: 100%;
  position: relative;
  overflow: hidden;

  .preview {
    height: 5rem;
    width: 100%;
    background: var(--ion-color-image-background);
    position: relative;
    padding: var(--spacing-spacing-2xs, 0.5rem);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }

    &:after {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      content: ' ';
      background: var(--ion-color-image-filter);
    }
  }

  .name {
    @include use-font("esbuild", "bold");
    font-size: var(--size-14, 0.875rem);
    font-weight: 700;
    line-height: var(--size-26, 1.625rem);
    padding: var(--spacing-spacing-4xs, 0.25rem) var(--spacing-spacing-s, 0.75rem);
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    img {
      transition: transform ease-in-out .3s;
    }

    &:hover .preview img {
      transform: scale(1.1);
    }
  }
}