@import "mixins";

/**
 * Material styles of Design System
 */


// Custom override
.cdk-program-focused {
  .mat-button-focus-overlay {
    opacity: 0 !important;
  }
}

.mat-step-icon-content {
  font-family: var(--ion-font-family-open-sans), serif;
}

// Tree
.mat-tree {
  background: transparent !important;
}

@include breakpoint-max(sm) {
  mat-stepper {
    .mat-horizontal-content-container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

// Tooltip
.right-menu-tooltip {
  font-size: 1.1rem;
  margin-top: 1.1rem !important;
}

.mat-tooltip.info-tooltip {
  // https://stackblitz.com/edit/angular-3wa7ea?file=src%2Fstyles.scss
  position: relative;

  background-color: rgba(var(--ion-color-dark-rgb), .95);
  border: rgba(var(--ion-color-dark-rgb), .95) 2px solid;
  border-radius: 10px;
  font-size: 1rem;

  &::after {
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid #0000008c;

    border-bottom-color: rgba(var(--ion-color-dark-rgb), .95);
  }
  &.above {
    overflow: initial;
    margin-bottom: 1rem;
    &:after {
      bottom: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(180deg);
    }
  }
}


// Autocomplete
.zip-code-list {
  min-width: 250px !important;
}
