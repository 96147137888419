
.introjs-tooltip {
  background-color: var(--ion-color-background);

  .introjs-tooltiptext {
    color: var(--ion-color-foreground);
    padding: .5rem 1rem;
  }
}

.introjs-video {
  max-width: 600px;
}

.introjs-helperLayer {
  box-shadow: rgba(var(--ion-color-dark-rgb), .8) 0 0 1px 2px,
  rgba(var(--ion-color-dark-rgb), .5) 0 0 0 5000px !important;
}

.introjs-dontShowAgain, .introjs-dontShowAgainAll {
  margin-top: .65rem;
  white-space: nowrap;

  input {
    width: 13px;
    height: 13px;
    margin-bottom: 0;
  }

  label {
    background-color: transparent;
    color: var(--ion-color-foreground);
  }
}

.introjs-button {
  background-color: var(--ion-color-background);
  color: var(--ion-color-foreground);
  text-shadow: none;

  &:hover, &:active, &:focus {
    color: var(--ion-color-foreground);
    background-color: var(--ion-color-background-tint);
  }

  &.introjs-disabled {
    opacity: 0.7;

    &:hover, &:active, &:focus {
      background-color: var(--ion-color-background);
    }
  }
}

