/**
 * Standard CSS animation
 */

/**
 * Blink animation with effect
 */
@keyframes blinkAnimation {
  0% {
    opacity: 1;
  }
  15% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.blink-animation {
  -webkit-animation: blinkAnimation 1s ease-in-out infinite; /* Chrome, Safari, Opera */
  animation: blinkAnimation 1s ease-in-out infinite;
}

/**
 * Pulse animation with effect
 */
@keyframes pulseAnimation {
  0% {
    opacity: 1;
    transform: scale(1.1);
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 0.5;
    transform: scale(.9);
  }
  100% {
    opacity: 1;
    transform: scale(1.1);
  }
}
.pulse-animation {
  -webkit-animation: pulseAnimation 1s ease-in-out infinite; /* Chrome, Safari, Opera */
  animation: pulseAnimation 1s ease-in-out infinite;
}

/**
 * Blink and swipe left animation with effect
 */
@keyframes blinkLeftAnimation {
  0% {
    opacity: 1;
  }
  15% {
    opacity: 1;
    transform: translateX(0);
  }
  50% {
    opacity: 0.5;
  }
  90% {
    transform: translateX(-5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.blink-left-animation {
  -webkit-animation: blinkLeftAnimation 1s ease-in-out infinite; /* Chrome, Safari, Opera */
  animation: blinkLeftAnimation 1s ease-in-out infinite;
}

/**
 * Notification Pulse effect
 */
@keyframes notificationPulseAnimation {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(var(--ion-color-notification-rgb), 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 6px rgba(var(--ion-color-notification-rgb), 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(var(--ion-color-notification-rgb), 0);
  }
}
.notification-pulse-animation {
  -webkit-animation: notificationPulseAnimation 2s ease-in-out infinite; /* Chrome, Safari, Opera */
  animation: notificationPulseAnimation 2s ease-in-out infinite;
}

/**
 * Firework animation
 */

@keyframes fireworkAnimation {
  0% {
    width: 0.5vmin;
    height: .5vmin;
    opacity: 1;
  }
  100% {
    width: 45vmin;
    height: 45vmin;
    opacity: 0;
  }
}

.firework-animation,
.firework-animation::before,
.firework-animation::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.5vmin;
  aspect-ratio: 1;
  background:
      /* random backgrounds */
      radial-gradient(circle, hsl(random(360), 100%, 50%) 0.2vmin, #0000 0) 50% 00%,
      radial-gradient(circle, hsl(random(360), 100%, 50%) 0.3vmin, #0000 0) 00% 50%,
      radial-gradient(circle, hsl(random(360), 100%, 50%) 0.5vmin, #0000 0) 50% 99%,
      radial-gradient(circle, hsl(random(360), 100%, 50%) 0.2vmin, #0000 0) 99% 50%,
      radial-gradient(circle, hsl(random(360), 100%, 50%) 0.3vmin, #0000 0) 80% 90%,
      radial-gradient(circle, hsl(random(360), 100%, 50%) 0.5vmin, #0000 0) 95% 90%,
      radial-gradient(circle, hsl(random(360), 100%, 50%) 0.5vmin, #0000 0) 10% 60%,
      radial-gradient(circle, hsl(random(360), 100%, 50%) 0.2vmin, #0000 0) 31% 80%,
      radial-gradient(circle, hsl(random(360), 100%, 50%) 0.3vmin, #0000 0) 80% 10%,
      radial-gradient(circle, hsl(random(360), 100%, 50%) 0.2vmin, #0000 0) 90% 23%,
      radial-gradient(circle, hsl(random(360), 100%, 50%) 0.3vmin, #0000 0) 45% 20%,
      radial-gradient(circle, hsl(random(360), 100%, 50%) 0.5vmin, #0000 0) 13% 24%
;
  background-size: 0.5vmin 0.5vmin;
  background-repeat: no-repeat;
  animation: fireworkAnimation 2s infinite;
}

.firework-animation.not-active {
  display: none;
}

.firework-animation::before {
  transform: translate(-50%, -50%) rotate(25deg) !important;
}

.firework-animation::after {
  transform: translate(-50%, -50%) rotate(-37deg) !important;
}
