/**
 * Breakpoints management definition
 *   - breakpoint-max(sm|md|lg|lx|xl)
 *   - breakpoint-min(sm|md|lg|lx|xl)
 */

// TODO: Use variable and assure that profile view always works

/**
 Breakpoint max
 = Breakpoint min - 1
 */
@mixin breakpoint-max($breakpoint) {
  @if $breakpoint==sm {
    @media(max-width: 575px) {
      @content
    }
  };

  @if $breakpoint==md {
    @media(max-width: 767px) {
      @content
    }
  };

  @if $breakpoint==lg {
    @media(max-width: 991px) {
      @content
    }
  };

  @if $breakpoint==lx {
    @media(max-width: 1091px) {
      @content
    }
  };

  @if $breakpoint==xl {
    @media(max-width: 1199px) {
      @content
    }
  };

  @if $breakpoint==xxl {
    @media(max-width: 1475px) {
      @content
    }
  };
}
/**
 Breakpoint min
 */
@mixin breakpoint-min($breakpoint) {
  @if $breakpoint==sm {
    @media(min-width: 576px) {
      @content
    }
  };

  @if $breakpoint==md {
    @media(min-width: 768px) {
      @content
    }
  };

  @if $breakpoint==lg {
    @media(min-width: 992px) {
      @content
    }
  };

  @if $breakpoint==lx {
    @media(min-width: 1092px) {
      @content
    }
  };

  @if $breakpoint==xl {
    @media(min-width: 1200px) {
      @content
    }
  };

  @if $breakpoint==xxl {
    @media(min-width: 1476px) {
      @content
    }
  };
}
