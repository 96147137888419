/**
 * Standard definition
 * Standard theming of main UI components
 *   - standard-background
 *   - standard-part-separator
 *   - standard-scrollbar
 *   - standard-form-input-round
 *   - standard-round-title
 *   - standard-table
 *   - standard-form-chip
 *   - standard-icon-value
 *   - standard-circle-button
 *   - standard-answer
 *   - standard-breadcrumbs
 *   - standard-no-data
 */

@import "default";

/**
 * Standard background for mobile and desktop
 * -> Which managing background color and border-radius
 */
@mixin standard-background() {
  background-color: var(--ion-color-background);

  @include breakpoint-min(md) {
    border-radius: var(--border-radius-default, 1rem);
  }
}

/**
 * Standard part separator
 * -> Which managing part separator for responsive
 */
@mixin standard-part-separator($resetLastChild: false) {
  margin-bottom: .5rem;

  @include breakpoint-min(md) {
    margin-bottom: .8rem;
  }

  @if ($resetLastChild) {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

/**
 * Default scrollbar
 */
@mixin standard-scrollbar() {
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--ion-color-light-shade);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--ion-color-light);
  }
}

/**
 * Standard round form input
 */
@mixin standard-form-input-round() {
  margin: 0;
  padding: .5rem 1rem;
  font-size: var(--size-text-default);
  color: var(--ion-color-dark);
  border-radius: var(--radius-radius-l, 0.5rem);
  border: 1px solid var(--ion-color-grey-400, #BBD2D9);
  background-color: var(--ion-color-background);

  &::placeholder {
    color: var(--ion-color-medium);
  }

  &[disabled] {
    background-color: var(--ion-color-grey-100);
  }

  &.invalid {
    border-color: var(--ion-color-danger);
  }
}

/**
 * Standard form select
 */
@mixin standard-form-select() {
  @include standard-form-input-round();

  min-height: var(--size-default);
  display: inline-flex;
  justify-content: flex-start;
  text-align: left;

  &::ng-deep .mat-select-trigger {
    height: unset;
  }
}

/**
 * Standard round title
 */
@mixin standard-round-title() {
  line-height: 1.2rem;
  text-transform: uppercase;
  font-family: var(--ion-font-family-open-sans), serif;
  font-weight: 700;
  font-size: .85rem;
  padding: .15rem .8rem;
  color: var(--ion-color-foreground-contrast);
  background-color: var(--ion-color-foreground);
  border-radius: var(--border-radius-title, 1rem);
}

/**
 * Table
 */
@mixin standard-table() {
  width: 100%;
  margin-top: .5rem;

  tr {
    width: 100%;
    border-bottom: 1px solid var(--ion-color-light);
    min-height: 30px;

    td {
      min-width: 80px;
      padding: .2rem .5rem;
      font-size: var(--size-text-default);

      &:first-child {
        flex-grow: 1;
      }
    }
  }

  thead {
    margin-bottom: .8rem;

    tr {
      border: none;
    }

    th {
      @include default-primary-title();

      font-family: var(--ion-font-family-open-sans), serif;
      font-weight: 700;
      font-size: .8rem;
      padding: .3rem;
      border-radius: 0;
      white-space: nowrap;

      &:first-child {
        border-radius: 1rem 0 0 1rem;
      }

      &:last-child {
        border-radius: 0 1rem 1rem 0;
      }
    }
  }

  tbody {
    tr:first-child {
      td {
        padding-top: 1rem;
      }
    }
  }
}

/**
 * Standard form chip
 */
@mixin standard-form-chip() {
  height: 32px;
  border-radius: var(--border-radius-inputs, 32px);
  line-height: 32px;
  min-width: 54px;
  font-size: .9rem;
  color: var(--ion-color-background);
  background-color: var(--ion-color-primary);
  text-align: center;
  font-family: var(--ion-font-family-open-sans), serif;
  font-weight: 700;
  margin-right: 1rem;

  &.is-good {
    background-color: var(--ion-color-green);
  }

  &.is-bad {
    background-color: var(--ion-color-red);
  }
}

/**
 * Standard icon value in flex disposition with overload theming
 */
@mixin standard-icon-value() {
  white-space: nowrap;
  color: var(--ion-color-foreground);
  margin-right: .5rem;
  min-width: 70px;
  display: flex;
  align-items: center;
  font-family: var(--ion-font-family-esbuild), serif;
  font-weight: 700;

  ion-icon {
    height: 14px;
    width: 14px;
    min-width: 14px;
    margin-right: .3rem;
  }

  span {
    position: relative;
    // --margin-offset--
    margin-top: .15rem;
  }

  span.normal {
    font-family: var(--ion-font-family-open-sans), serif;
    font-size: var(--size-text-default);
    // --margin-offset-- on top
    margin: 0 .3rem 0 .3rem;
  }

  span.icon-only {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.reward-earned {
    color: var(--ion-color-earned);
  }

  &.dark {
    color: var(--ion-color-dark);
  }

  &.success {
    color: var(--ion-color-success);
  }
}


/**
 * Buttons
 */
@mixin standard-circle-button() {
  --border-radius: 50%;
  --padding-end: 0.5rem;
  --padding-start: 0.5rem;
  --padding-top: 0.5rem;
  --padding-bottom: 0.5rem;
  width: 35px;
  height: 35px;
  margin: 0 .25rem;
}

/**
 * Buttons
 */
@mixin standard-round-square-button() {
  --border-radius: calc(var(--size-buttons-height, 36px) / 4);
  --padding-end: 0.5rem;
  --padding-start: 0.5rem;
  --padding-top: 0.5rem;
  --padding-bottom: 0.5rem;
  width: var(--size-buttons-height, 36px);
  height: var(--size-buttons-height, 36px);
  min-height: var(--size-buttons-height, 36px);
  margin: 0 .25rem;
}

/**
 * Standard answer theme
 */
@mixin standard-answer() {
  --border-radius-inputs: var(--border-radius-answer-item);

  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--ion-color-light);
  border-radius: var(--border-radius-inputs, 26px);
  line-height: 26px;
  cursor: pointer;

  .num {
    @include standard-form-chip();

    height: 26px;
    line-height: 26px;
  }

  .text {
    color: var(--ion-color-tertiary);
  }

  &.user-answer {
    background-color: var(--ion-color-primary);

    .text {
      color: var(--ion-color-primary-contrast);
    }
  }
}

/**
 * Standard dropzone
 */
@mixin standard-dropzone() {
  border: 2px dashed var(--dz-color, var(--ion-color-grey-400, #BBD2D9));
  background-color: var(--dz-background-color, var(--ion-color-grey-100, #F6FAFC));
  color: var(--dz-color, var(--ion-color-grey-600, #406073));
  border-radius: var(--dz-border-radius, var(--radius-radius-l, 0.5rem));
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--dz-height, 180px);

  &.starter {
    padding: .5rem;
    cursor: pointer;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  &.invalid {
    border-color: var(--ion-color-danger);
  }

  ngx-dropzone-label {
    padding: 1rem;
  }
}

/**
 * Standard breadcrumb based on ul > li
 */
@mixin standard-breadcrumb() {
  list-style: none;
  padding-inline: unset;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0;
  margin-top: 0;
  flex-wrap: wrap;

  li {
    list-style: none;
    padding: 0;
    margin: .2rem 0;

    a {
      cursor: pointer;
      color: var(--ion-color-base);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

    }

    &:after {
      content: var(--separator, '|');
      margin: 0 .5rem;
      text-decoration: none !important;
      color: var(--ion-color-base);
    }

    &:last-child:after {
      content: none;
    }
  }

  &.weight-last li:last-child a {
    font-weight: bold;
  }
}

/**
 * Standard no data
 */
@mixin standard-no-data() {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  padding: .5rem;
  width: 100%;

  .icon {
    margin-top: 1rem;

    ion-icon {
      height: 60px;
      width: 60px;
    }
  }

  .message {
    color: var(--ion-color-dark);
    font-size: 1rem;
    text-align: center;
    max-width: 500px;
    margin: .5rem auto 0 auto;
  }

  .title {
    @include use-font("esbuild", "bold");
    font-size: 1.2rem;
    color: var(--ion-color-dark);
  }

  .preview {
    margin-top: 1.5rem;
  }

  @include breakpoint-min(md) {
    padding: 0;
  }
}

/**
 * Standard flex columns
 * > Dispose content into columns
 */
@mixin standard-flex-cols() {
  .cols {
    .col.with-margin,
    .col-2.with-margin,
    .col-3.with-margin {
      margin-bottom: 1rem;
    }

    @include breakpoint-min(md) {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      .col, .col-2, .col-3 {
        flex: 1;
      }
    }
  }
}

/**
 * Standard round button
 */
@mixin standard-round-button() {
  @include util-set-ion-padding(6px);

  min-height: var(--size-buttons-height, 26px);
  height: var(--size-buttons-height, 26px);
  width: var(--size-buttons-height, 26px);
}
