/**
 * Ionic components styles of Design System
 */

// Header, Content & Footer
ion-header.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}

ion-header.header-md {
  box-shadow: none;
  -webkit-box-shadow: none;
}

ion-footer.footer-ios ion-toolbar:first-of-type {
  --border-width: 0;
}

ion-content {
  --background: var(--ion-color-light);
  --padding-end: 0;
  --padding-start: 0;

  @include breakpoint-min(md) {
    @include util-set-ion-padding(1rem);
  }

  @include breakpoint-min(lg) {
    --padding-bottom: 4rem;
  }

  div.c-box {
    margin-left: auto;
    margin-right: auto;
  }
}

// Buttons & Icons
ion-button {
  --padding-end: var(--spacing-spacing-2xl, 1.5rem);
  --padding-start: var(--spacing-spacing-2xl, 1.5rem);
  --border-radius: var(--radius-radius-2xl, 1.5rem);
  --padding-top: var(--spacing-spacing-s, 0.75rem);
  --padding-bottom: var(--spacing-spacing-2xs, 0.5rem);
  --ion-font-family: var(--ion-font-family-esbuild), serif;

  letter-spacing: normal;
  font-family: var(--ion-font-family-esbuild), serif;
  font-weight: normal;
  font-size: var(--size-16);
  height: var(--size-large);
  text-transform: none;

  ion-label {
    line-height: normal;
  }

  ion-spinner {
    height: var(--size-20);
    margin-right: var(--spacing-spacing-2xs, 0.5rem);
  }

  // DS BT-Square Button in store
  &.bt-square {
    --color: var(--ion-color-grey-600);
    --background: var(--ion-color-grey-200);
    --background-hover: var(--ion-color-grey-300);
    --padding-end: var(--spacing-spacing-s, 0.75rem);
    --padding-start: var(--spacing-spacing-s, 0.75rem);
    --padding-bottom: var(--spacing-spacing-2xs, 0.5rem);
    --padding-top: var(--spacing-spacing-2xs, 0.5rem);
    --border-radius: var(--radius-radius-l, 0.5rem);
    --box-shadow: none;
    --background-activated-opacity: 0.08;

    font-size: var(--size-12);
  }

  &.button-small {
    --padding-end: var(--spacing-spacing-l, 1rem);
    --padding-start: var(--spacing-spacing-l, 1rem);

    ion-spinner {
      height: var(--size-16);
    }
  }

  &.button-large {
    --padding-end: var(--spacing-spacing-l, 1rem);
    --padding-start: var(--spacing-spacing-l, 1rem);
  }

  &.circle {
    --border-radius: 50%;
    --padding-end: var(--spacing-spacing-4xs, 0.25rem);
    --padding-start: var(--spacing-spacing-4xs, 0.25rem);
    --padding-bottom: var(--spacing-spacing-4xs, 0.25rem);
    --padding-top: var(--spacing-spacing-4xs, 0.25rem);
    width: 36px;
    height: 36px;
  }

  // DS BT
  &.bt-ds {
    --padding-end: var(--spacing-spacing-2xl, 1.5rem);
    --padding-start: var(--spacing-spacing-2xl, 1.5rem);
    --border-radius: var(--radius-radius-2xl, 1.5rem);
    --box-shadow: none;
    margin: 0;
  }

  &.bt-x-small {
    --border-radius: var(--radius-radius-2xl, 1.5rem);
    --padding-end: var(--spacing-spacing-l, 1rem);
    --padding-start: var(--spacing-spacing-l, 1rem);
    --box-shadow: none;
    margin: 0;
    height: 1.5rem;
    min-height: 1.5rem;
    font-size: var(--size-12, 0.75rem);
    line-height: var(--size-16, 1rem); /* 133.333% */
  }

  // BT OUTLINE STYLE
  &.bt-outline {
    --border-color: var(--ion-color-base);
    --border-width: 1px;
    --border-style: solid;
    --box-shadow: none;
    --border-radius: var(--radius-radius-2xl, 1.5rem);
    --background-activated-opacity: 0.08;

    &::part(native) {
      color: var(--ion-color-base);
      background: var(--ion-color-contrast);
    }

    &:hover::part(native) {
      color: var(--ion-color-tint);
      border-color: var(--ion-color-tint);

      @media screen and (min-width: 768px) {
        filter: brightness(95%);
      }
    }
  }

  &.bt-pagination {
    --box-shadow: none;
    --border-radius: var(--radius-radius-m, 0.25rem);
    --padding-start: var(--spacing-spacing-4xs, 0.25rem);
    --padding-end: var(--spacing-spacing-4xs, 0.25rem);

    &::part(native) {
      color: var(--ion-color-base);
      background: var(--ion-color-grey-200, #EDF5F7);
    }

    &:hover::part(native) {
      color: var(--ion-color-tint);
      border-color: var(--ion-color-grey-300, #E6F1F5);
    }
  }

  @media screen and (min-width: 768px) {
    height: var(--size-small);
    font-size: var(--size-14, 0.875rem);
    line-height: var(--size-20, 1.25rem); /* 142.857% */

    &.button-small {
      height: var(--size-small);
    }

    &.button-default {
      height: var(--size-default);
    }

    &.button-large {
      height: var(--size-large);
    }
  }
}

.ios ion-button {
  outline: none;
}

ion-icon {
  pointer-events: none;
}

ion-item {
  &.bt-back {
    --border-radius: var(--radius-radius-l, 0.5rem);
    --background: var(--ion-color-grey-100, #F6FAFC);
    --color: var(--ion-color-grey-600, #406073);
    --ion-font-family: var(--ion-font-family-title), serif;

    font-size: var(--size-14, 0.875rem);
    margin-bottom: var(--spacing-spacing-2xs, 0.5rem);
    width: 100%;

    ion-icon {
      color: var(--ion-color-primary);
      border-radius: var(--radius-radius-m, 0.25rem);
      background: var(--ion-color-grey-300, #E6F1F5);
      padding: var(--spacing-spacing-4xs, 0.25rem);
    }
  }
}

// Inputs
ion-input {
  --color: var(--ion-color-dark);
  --background: var(--ion-color-light);
  --padding-end: 1rem !important;
  --padding-start: 1rem !important;
  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
  height: 36px;
  line-height: 36px;
  font-size: 1rem !important;
  border-radius: 2rem;
  border: 1px solid var(--ion-color-medium);
}

ion-toggle {
  min-width: 36px;
}

// Modals, Loading & Alerts
ion-modal {
  --border-radius: 10px !important;

  @include breakpoint-max(md) {
    --border-radius: 0 !important;
    --max-height: 100%;
    --max-width: 100%;
  }
}

ion-alert {
  .alert-wrapper {
    border-radius: var(--border-radius-default, 1rem) !important;
    margin: .5rem;
  }
}

ion-loading.sc-ion-loading-md-h, ion-loading.sc-ion-loading-ios-h {
  --background: var(--ion-color-background);
  color: var(--ion-color-foreground);
}


// Actions sheet
.select-actions {
  &.ios {
    .action-sheet-button {
      --button-color: var(--ion-color-foreground);
      font-size: 1.1rem;
    }

    .action-sheet-icon {
      margin-right: .5rem;
      font-size: 1.5rem;
    }
  }

  .action-sheet-group.action-sheet-group-cancel .action-sheet-button {
    --button-color: var(--ion-color-danger);
    @include use-font("esbuild", "bold");

    .action-sheet-icon {
      color: var(--ion-color-danger);
    }
  }
}

.action-sheet-button.sc-ion-action-sheet-ios .action-sheet-icon.sc-ion-action-sheet-ios {
  font-size: 1.5rem;
}


