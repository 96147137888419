// App Variables and Theming for Design System.
//
// For more info on Ionic theming, please see:
// http://ionicframework.com/docs/theming/
//
// 1. Colors
// 2. Fonts
// 3. Breakpoints
// 4. Sizes & Border radius


/** Ionic CSS Variables **/
:root {
  /** ***
   * 1. Colors
   */
  // primary
  --ion-color-primary: #000000;
  --ion-color-primary-rgb: 0, 0, 0;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #000000;
  --ion-color-primary-tint: #1a1a1a;

  // success
  --ion-color-success: #64be62;
  --ion-color-success-rgb: 100,190,98;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #58a756;
  --ion-color-success-tint: #74c572;

  // warning
  --ion-color-warning: #FF9268;
  --ion-color-warning-rgb: 255, 146, 104;
  --ion-color-warning-contrast: #FFFFFF;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0805c;
  --ion-color-warning-tint: #ff9d77;

  // danger
  --ion-color-danger: #E65252;
  --ion-color-danger-rgb: 230, 82, 82;
  --ion-color-danger-contrast: #FFFFFF;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ca4848;
  --ion-color-danger-tint: #e96363;

  // dark
  --ion-color-dark: #404041;
  --ion-color-dark-rgb: 64, 64, 65;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #383839;
  --ion-color-dark-tint: #535354;

  // medium
  --ion-color-medium: #A7A9AB;
  --ion-color-medium-rgb: 167, 169, 171;
  --ion-color-medium-contrast: #FFFFFF;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #939596;
  --ion-color-medium-tint: #b0b2b3;

  // light (DS Fond Page)
  --ion-color-light: #edf1f2;
  --ion-color-light-rgb: 237, 241, 242;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d1d4d5;
  --ion-color-light-tint: #eff2f3;

  // Foreground (Black / White)
  --ion-color-foreground: #000000;
  --ion-color-foreground-rgb: 0, 0, 0;
  --ion-color-foreground-contrast: #ffffff;
  --ion-color-foreground-contrast-rgb: 255, 255, 255;
  --ion-color-foreground-shade: #000000;
  --ion-color-foreground-tint: #1a1a1a;

  // Background (White / Black)
  --ion-color-background: #FFFFFF;
  --ion-color-background-rgb: 255, 255, 255;
  --ion-color-background-contrast: #000000;
  --ion-color-background-contrast-rgb: 0, 0, 0;
  --ion-color-background-shade: #e0e0e0;
  --ion-color-background-tint: #ffffff;

  // Coins
  --ion-color-coins: #EA9D21;
  --ion-color-coins-rgb: 234, 157, 33;
  --ion-color-coins-contrast: #ffffff;
  --ion-color-coins-contrast-rgb: 255, 255, 255;
  --ion-color-coins-shade: #ce8a1d;
  --ion-color-coins-tint: #F7F0E5;

  // Grey
  --ion-color-grey-100: #F6FAFC;
  --ion-color-grey-100-rgb: 246, 250, 252;
  --ion-color-grey-100-contrast: #000000;
  --ion-color-grey-100-contrast-rgb: 0, 0, 0;
  --ion-color-grey-100-shade: #d8dcde;
  --ion-color-grey-100-tint: #f7fbfc;

  --ion-color-grey-200: #EDF5F7;
  --ion-color-grey-200-rgb: 237, 245, 247;
  --ion-color-grey-200-contrast: #000000;
  --ion-color-grey-200-contrast-rgb: 0, 0, 0;
  --ion-color-grey-200-shade: #d1d8d9;
  --ion-color-grey-200-tint: #eff6f8;

  --ion-color-grey-300: #E6F1F5;
  --ion-color-grey-300-rgb: 230, 241, 245;
  --ion-color-grey-300-contrast: #000000;
  --ion-color-grey-300-contrast-rgb: 0, 0, 0;
  --ion-color-grey-300-shade: #cad4d8;
  --ion-color-grey-300-tint: #e9f2f6;

  --ion-color-grey-400: #BBD2D9;
  --ion-color-grey-400-rgb: 187, 210, 217;
  --ion-color-grey-400-contrast: #000000;
  --ion-color-grey-400-contrast-rgb: 0, 0, 0;
  --ion-color-grey-400-shade: #a5b9bf;
  --ion-color-grey-400-tint: #c2d7dd;

  --ion-color-grey-500: #8199A7;
  --ion-color-grey-500-rgb: 129, 153, 167;
  --ion-color-grey-500-contrast: #000000;
  --ion-color-grey-500-contrast-rgb: 0, 0, 0;
  --ion-color-grey-500-shade: #728793;
  --ion-color-grey-500-tint: #8ea3b0;

  --ion-color-grey-600: #406073;
  --ion-color-grey-600-rgb: 64, 96, 115;
  --ion-color-grey-600-contrast: #ffffff;
  --ion-color-grey-600-contrast-rgb: 255, 255, 255;
  --ion-color-grey-600-shade: #385465;
  --ion-color-grey-600-tint: #537081;

  // *** TODO: Old colors to be removed ***

  // secondary
  --ion-color-secondary: #000000;
  --ion-color-secondary-rgb: 0, 0, 0;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #000000;
  --ion-color-secondary-tint: #1a1a1a;

  // tertiary
  --ion-color-tertiary: #000000;
  --ion-color-tertiary-rgb: 0, 0, 0;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #000000;
  --ion-color-tertiary-tint: #1a1a1a;

  // earned
  --ion-color-earned: var(--ion-color-success);
  --ion-color-earned-rgb: var(--ion-color-success-rgb);
  --ion-color-earned-contrast: var(--ion-color-success-contrast);
  --ion-color-earned-contrast-rgb: var(--ion-color-success-contrast-rgb);
  --ion-color-earned-shade: var(--ion-color-success-shade);
  --ion-color-earned-tint: var(--ion-color-success-tint);

  // red
  --ion-color-red: #FF2468;
  --ion-color-red-rgb: 255, 36, 104;
  --ion-color-red-contrast: #ffffff;
  --ion-color-red-contrast-rgb: 255, 255, 255;
  --ion-color-red-shade: #e0205c;
  --ion-color-red-tint: #ff3a77;

  // Blue
  --ion-color-blue: #85C8F2;
  --ion-color-blue-rgb: 133, 200, 242;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255, 255, 255;
  --ion-color-blue-shade: #75b0d5;
  --ion-color-blue-tint: #91cef3;

  // Green
  --ion-color-green: var(--ion-color-success);
  --ion-color-green-rgb: var(--ion-color-success-rgb);
  --ion-color-green-contrast: var(--ion-color-success-contrast);
  --ion-color-green-contrast-rgb: var(--ion-color-success-contrast-rgb);
  --ion-color-green-shade: var(--ion-color-success-shade);
  --ion-color-green-tint: var(--ion-color-success-tint);

  // Yellow
  --ion-color-yellow: #F2B705;
  --ion-color-yellow-rgb: 242, 183, 5;
  --ion-color-yellow-contrast: #ffffff;
  --ion-color-yellow-contrast-rgb: 255, 255, 255;
  --ion-color-yellow-shade: #d5a104;
  --ion-color-yellow-tint: #f3be1e;

  --ion-color-congrats-yellow: #EBA046;
  --ion-color-congrats-yellow-rgb: 235, 160, 70;
  --ion-color-congrats-yellow-contrast: #000000;
  --ion-color-congrats-yellow-contrast-rgb: 0, 0, 0;
  --ion-color-congrats-yellow-shade: #cf8d3e;
  --ion-color-congrats-yellow-tint: #edaa59;

  // Purple
  --ion-color-purple: #7663F2;
  --ion-color-purple-rgb: 118, 99, 242;
  --ion-color-purple-contrast: #ffffff;
  --ion-color-purple-contrast-rgb: 255, 255, 255;
  --ion-color-purple-shade: #6857d5;
  --ion-color-purple-tint: #8473f3;

  --ion-color-united-purple: #B33EEF;
  --ion-color-united-purple-rgb: 179, 62, 239;
  --ion-color-united-purple-contrast: #000000;
  --ion-color-united-purple-contrast-rgb: 0, 0, 0;
  --ion-color-united-purple-shade: #9e37d2;
  --ion-color-united-purple-tint: #bb51f1;

  // Youtube
  --ion-color-youtube: #FF0000;
  --ion-color-youtube-rgb: 255, 0, 0;
  --ion-color-youtube-contrast: #ffffff;
  --ion-color-youtube-contrast-rgb: 255, 255, 255;
  --ion-color-youtube-shade: #e00000;
  --ion-color-youtube-tint: #ff1a1a;

  // Challenges
  --ion-color-challenges: #000000;
  --ion-color-challenges-rgb: 0, 0, 0;
  --ion-color-challenges-contrast: #ffffff;
  --ion-color-challenges-contrast-rgb: 255, 255, 255;
  --ion-color-challenges-shade: #000000;
  --ion-color-challenges-tint: #1a1a1a;

  // Notification
  --ion-color-notification: #FF2468;
  --ion-color-notification-rgb: 255, 36, 104;
  --ion-color-notification-contrast: #ffffff;
  --ion-color-notification-contrast-rgb: 255, 255, 255;
  --ion-color-notification-shade: #e0205c;
  --ion-color-notification-tint: #ff3a77;

  // Pexels
  --ion-color-pexels: #01A081;
  --ion-color-pexels-rgb: 1, 160, 129;
  --ion-color-pexels-contrast: #ffffff;
  --ion-color-pexels-contrast-rgb: 255, 255, 255;
  --ion-color-pexels-shade: #018d72;
  --ion-color-pexels-tint: #1aaa8e;

  --ion-color-image-background: #ffffff;
  --ion-color-image-filter: rgba(66, 109, 132, 0.15);

  // --ion-background-color: var(--ion-color-light);

  /** ***
   * 2. Fonts
   */
  --ion-font-family: "OpenSans", -apple-system, BlinkMacSystemFont, Arial, "Roboto", sans-serif;

  --ion-font-family-open-sans: "OpenSans", var(--ion-font-family);
  --ion-font-family-esbuild: "ESBuild", var(--ion-font-family);

  /** ***
   * 3. Breakpoints
   */
  --breakpoint-sm: 576px; // Mobile
  --breakpoint-md: 768px; // Small tablet
  --breakpoint-lg: 992px; // Medium tablet
  --breakpoint-lx: 1092px; // Large tablet
  --breakpoint-xl: 1200px; // Desktop
  --breakpoint-xxl: 1476px;

  /** ***
   * 4. Size, Spacing & Radius
   */
  --size-2: 0.125rem;
  --size-4: 0.25rem;
  --size-6: 0.375rem;
  --size-8: 0.5rem;
  --size-10: 0.625rem;
  --size-12: 0.75rem;
  --size-14: 0.875rem;
  --size-16: 1rem;
  --size-18: 1.125rem;
  --size-20: 1.25rem;
  --size-22: 1.375rem;
  --size-24: 1.5rem;
  --size-26: 1.625rem;
  --size-28: 1.75rem;
  --size-30: 1.875rem;
  --size-32: 2rem;
  --size-36: 2.25rem;
  --size-38: 2.375rem;
  --size-40: 2.5rem;
  --size-44: 2.75rem;
  --size-48: 3rem;
  --size-52: 3.25rem;
  --size-56: 3.5rem;
  --size-60: 3.75rem;
  --size-80: 5rem;
  --size-100: 6.25rem;

  --radius-radius-m: var(--size-4);
  --radius-radius-l: var(--size-8);
  --radius-radius-xl: var(--size-16);
  --radius-radius-2xl: var(--size-24);
  --radius-radius-3xl: var(--size-32);

  --spacing-spacing-4xs: var(--size-4);
  --spacing-spacing-2xs: var(--size-8);
  --spacing-spacing-s: var(--size-12);
  --spacing-spacing-l: var(--size-16);
  --spacing-spacing-xl: var(--size-20);
  --spacing-spacing-2xl: var(--size-24);
  --spacing-spacing-3xl: var(--size-32);
  --spacing-spacing-4xl: var(--size-40);
  --spacing-spacing-5xl: var(--size-60);
  --spacing-spacing-6xl: var(--size-80);
  --spacing-spacing-7xl: var(--size-100);

  --size-side-menu-width: 16.75rem;
  --size-top-product-height-desktop: 20rem;
  --size-top-product-height-mobile: 14rem;
  --size-product-card-width: 16rem;
  --size-buttons-height: 36px;

  --size-text-small: var(--size-12);
  --size-text-default: var(--size-14);
  --size-text-large: var(--size-18);

  --size-small: var(--size-32);
  --size-default: var(--size-44);
  --size-large: var(--size-52);

  // TODO: Old DS to remove
  --border-radius-default: 1rem;
  --border-radius-buttons: .8rem;
  --border-radius-inputs: .8rem;
  --border-radius-inputs-small: .6rem;
  --border-radius-textareas: .8rem;
  --border-radius-dates: .4rem;
  --border-radius-dropzone: .8rem;
  --border-radius-e-store: .8rem;
  --border-radius-badges: .5rem;
  --border-radius-answer-item: .6rem;
  --border-radius-title: .5rem;
}

.ion-color-foreground {
  --ion-color-base: var(--ion-color-foreground);
  --ion-color-base-rgb: var(--ion-color-foreground-rgb);
  --ion-color-contrast: var(--ion-color-foreground-contrast);
  --ion-color-contrast-rgb: var(--ion-color-foreground-contrast-rgb);
  --ion-color-shade: var(--ion-color-foreground-shade);
  --ion-color-tint: var(--ion-color-foreground-tint);
}

.ion-color-background {
  --ion-color-base: var(--ion-color-background);
  --ion-color-base-rgb: var(--ion-color-background-rgb);
  --ion-color-contrast: var(--ion-color-background-contrast);
  --ion-color-contrast-rgb: var(--ion-color-background-contrast-rgb);
  --ion-color-shade: var(--ion-color-background-shade);
  --ion-color-tint: var(--ion-color-background-tint);
}

.ion-color-coins {
  --ion-color-base: var(--ion-color-coins);
  --ion-color-base-rgb: var(--ion-color-coins-rgb);
  --ion-color-contrast: var(--ion-color-coins-contrast);
  --ion-color-contrast-rgb: var(--ion-color-coins-contrast-rgb);
  --ion-color-shade: var(--ion-color-coins-shade);
  --ion-color-tint: var(--ion-color-coins-tint);
}

$greyVariants: 100, 200, 300, 400, 500, 600;
@each $greyVariant in $greyVariants {
  .ion-color-grey-#{$greyVariant} {
    --ion-color-base: var(--ion-color-grey-#{$greyVariant});
    --ion-color-base-rgb: var(--ion-color-grey-#{$greyVariant}-rgb);
    --ion-color-contrast: var(--ion-color-grey-#{$greyVariant}-contrast);
    --ion-color-contrast-rgb: var(--ion-color-grey-#{$greyVariant}-contrast-rgb);
    --ion-color-shade: var(--ion-color-grey-#{$greyVariant}-shade);
    --ion-color-tint: var(--ion-color-grey-#{$greyVariant}-tint);
  }
}

.ion-color-blue {
  --ion-color-base: var(--ion-color-blue);
  --ion-color-base-rgb: var(--ion-color-blue-rgb);
  --ion-color-contrast: var(--ion-color-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-shade);
  --ion-color-tint: var(--ion-color-blue-tint);
}

.ion-color-red {
  --ion-color-base: var(--ion-color-red);
  --ion-color-base-rgb: var(--ion-color-red-rgb);
  --ion-color-contrast: var(--ion-color-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-red-shade);
  --ion-color-tint: var(--ion-color-red-tint);
}

.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-base-rgb: var(--ion-color-green-rgb);
  --ion-color-contrast: var(--ion-color-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-shade);
  --ion-color-tint: var(--ion-color-green-tint);
}

.ion-color-yellow {
  --ion-color-base: var(--ion-color-yellow);
  --ion-color-base-rgb: var(--ion-color-yellow-rgb);
  --ion-color-contrast: var(--ion-color-yellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow-shade);
  --ion-color-tint: var(--ion-color-yellow-tint);
}

.ion-color-congrats-yellow {
  --ion-color-base: var(--ion-color-congrats-yellow);
  --ion-color-base-rgb: var(--ion-color-congrats-yellow-rgb);
  --ion-color-contrast: var(--ion-color-congrats-yellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-congrats-yellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-congrats-yellow-shade);
  --ion-color-tint: var(--ion-color-congrats-yellow-tint);
}

.ion-color-purple {
  --ion-color-base: var(--ion-color-purple);
  --ion-color-base-rgb: var(--ion-color-purple-rgb);
  --ion-color-contrast: var(--ion-color-purple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple-shade);
  --ion-color-tint: var(--ion-color-purple-tint);
}

.ion-color-united-purple {
  --ion-color-base: var(--ion-color-united-purple);
  --ion-color-base-rgb: var(--ion-color-united-purple-rgb);
  --ion-color-contrast: var(--ion-color-united-purple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-united-purple-contrast-rgb);
  --ion-color-shade: var(--ion-color-united-purple-shade);
  --ion-color-tint: var(--ion-color-united-purple-tint);
}

.ion-color-challenges {
  --ion-color-base: var(--ion-color-challenges);
  --ion-color-base-rgb: var(--ion-color-challenges-rgb);
  --ion-color-contrast: var(--ion-color-challenges-contrast);
  --ion-color-contrast-rgb: var(--ion-color-challenges-contrast-rgb);
  --ion-color-shade: var(--ion-color-challenges-shade);
  --ion-color-tint: var(--ion-color-challenges-tint);
}

.ion-color-notification {
  --ion-color-base: var(--ion-color-notification);
  --ion-color-base-rgb: var(--ion-color-notification-rgb);
  --ion-color-contrast: var(--ion-color-notification-contrast);
  --ion-color-contrast-rgb: var(--ion-color-notification-contrast-rgb);
  --ion-color-shade: var(--ion-color-notification-shade);
  --ion-color-tint: var(--ion-color-notification-tint);
}

.ion-color-pexels {
  --ion-color-base: var(--ion-color-pexels);
  --ion-color-base-rgb: var(--ion-color-pexels-rgb);
  --ion-color-contrast: var(--ion-color-pexels-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pexels-contrast-rgb);
  --ion-color-shade: var(--ion-color-pexels-shade);
  --ion-color-tint: var(--ion-color-pexels-tint);
}

.ion-color-youtube {
  --ion-color-base: var(--ion-color-youtube);
  --ion-color-base-rgb: var(--ion-color-youtube-rgb);
  --ion-color-contrast: var(--ion-color-youtube-contrast);
  --ion-color-contrast-rgb: var(--ion-color-youtube-contrast-rgb);
  --ion-color-shade: var(--ion-color-youtube-shade);
  --ion-color-tint: var(--ion-color-youtube-tint);
}

.ion-color-earned {
  --ion-color-base: var(--ion-color-earned);
  --ion-color-base-rgb: var(--ion-color-earned-rgb);
  --ion-color-contrast: var(--ion-color-earned-contrast);
  --ion-color-contrast-rgb: var(--ion-color-earned-contrast-rgb);
  --ion-color-shade: var(--ion-color-earned-shade);
  --ion-color-tint: var(--ion-color-earned-tint);
}

body.dark-theme {
  // primary
  --ion-color-primary: #ffffff;
  --ion-color-primary-rgb: 255, 255, 255;
  --ion-color-primary-contrast: #1D1E20;
  --ion-color-primary-contrast-rgb: 29, 30, 32;
  --ion-color-primary-shade: #e0e0e0;
  --ion-color-primary-tint: #ffffff;

  // success
  --ion-color-success: #449e42;
  --ion-color-success-rgb: 68,158,66;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #3c8b3a;
  --ion-color-success-tint: #57a855;

  // warning
  --ion-color-warning: #992b00;
  --ion-color-warning-rgb: 153, 43, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #872600;
  --ion-color-warning-tint: #a3401a;

  // danger
  --ion-color-danger: #ae1919;
  --ion-color-danger-rgb: 174, 25, 25;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #991616;
  --ion-color-danger-tint: #991616;

  // dark
  --ion-color-dark: #bfbfc0;
  --ion-color-dark-rgb: 191, 191, 192;
  --ion-color-dark-contrast: #1D1E20;
  --ion-color-dark-contrast-rgb: 29, 30, 32;
  --ion-color-dark-shade: #a8a8a9;
  --ion-color-dark-tint: #c5c5c6;

  // medium
  --ion-color-medium: #919191;
  --ion-color-medium-rgb: 145, 145, 145;
  --ion-color-medium-contrast: #1D1E20;
  --ion-color-medium-contrast-rgb: 29, 30, 32;
  --ion-color-medium-shade: #808080;
  --ion-color-medium-tint: #9c9c9c;

  // light
  --ion-color-light: #292A2C;
  --ion-color-light-rgb: 41,42,44;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #242527;
  --ion-color-light-tint: #3e3f41;

  // Foreground
  --ion-color-foreground: #ffffff;
  --ion-color-foreground-rgb: 255, 255, 255;
  --ion-color-foreground-contrast: #1D1E20;
  --ion-color-foreground-contrast-rgb: 29, 30, 32;
  --ion-color-foreground-shade: #ffffff;
  --ion-color-foreground-tint: #e0e0e0;

  // Background
  --ion-color-background: #1D1E20;
  --ion-color-background-rgb: 29, 30, 32;
  --ion-color-background-contrast: #ffffff;
  --ion-color-background-contrast-rgb: 255, 255, 255;
  --ion-color-background-shade: #1a1a1c;
  --ion-color-background-tint: #343536;

  // Coins
  --ion-color-coins: #EA9D21;
  --ion-color-coins-rgb: 234, 157, 33;
  --ion-color-coins-contrast: #ffffff;
  --ion-color-coins-contrast-rgb: 255, 255, 255;
  --ion-color-coins-shade: #ce8a1d;
  --ion-color-coins-tint: #443A2A;

  // Grey
  --ion-color-grey-100: #333333;
  --ion-color-grey-100-rgb: 51, 51, 51;
  --ion-color-grey-100-contrast: #1D1E20;
  --ion-color-grey-100-contrast-rgb: 29, 30, 32;
  --ion-color-grey-100-shade: #2d2d2d;
  --ion-color-grey-100-tint: #474747;

  --ion-color-grey-200: #383838;
  --ion-color-grey-200-rgb: 61, 61, 61;
  --ion-color-grey-200-contrast: #1D1E20;
  --ion-color-grey-200-contrast-rgb: 29, 30, 32;
  --ion-color-grey-200-shade: #313131;
  --ion-color-grey-200-tint: #4c4c4c;

  --ion-color-grey-300: #3D3D3D;
  --ion-color-grey-300-rgb: 66, 66, 66;
  --ion-color-grey-300-contrast: #1D1E20;
  --ion-color-grey-300-contrast-rgb: 29, 30, 32;
  --ion-color-grey-300-shade: #363636;
  --ion-color-grey-300-tint: #505050;

  --ion-color-grey-400: #424242;
  --ion-color-grey-400-rgb: 187, 210, 217;
  --ion-color-grey-400-contrast: #1D1E20;
  --ion-color-grey-400-contrast-rgb: 29, 30, 32;
  --ion-color-grey-400-shade: #3a3a3a;
  --ion-color-grey-400-tint: #555555;

  --ion-color-grey-500: #CCCCCC;
  --ion-color-grey-500-rgb: 129, 153, 167;
  --ion-color-grey-500-contrast: #1D1E20;
  --ion-color-grey-500-contrast-rgb: 29, 30, 32;
  --ion-color-grey-500-shade: #b4b4b4;
  --ion-color-grey-500-tint: #d1d1d1;

  --ion-color-grey-600: #E5E5E5;
  --ion-color-grey-600-rgb: 64, 96, 115;
  --ion-color-grey-600-contrast: #1D1E20;
  --ion-color-grey-600-contrast-rgb: 29, 30, 32;
  --ion-color-grey-600-shade: #cacaca;
  --ion-color-grey-600-tint: #e8e8e8;


  // secondary
  --ion-color-secondary: #ffffff;
  --ion-color-secondary-rgb: 255, 255, 255;
  --ion-color-secondary-contrast: #1D1E20;
  --ion-color-secondary-contrast-rgb: 29, 30, 32;
  --ion-color-secondary-shade: #e0e0e0;
  --ion-color-secondary-tint: #ffffff;

  // tertiary
  --ion-color-tertiary: #ffffff;
  --ion-color-tertiary-rgb: 255, 255, 255;
  --ion-color-tertiary-contrast: #1D1E20;
  --ion-color-tertiary-contrast-rgb: 29, 30, 32;
  --ion-color-tertiary-shade: #e0e0e0;
  --ion-color-tertiary-tint: #ffffff;

  // earned
  --ion-color-earned: var(--ion-color-success);
  --ion-color-earned-rgb: var(--ion-color-success-rgb);
  --ion-color-earned-contrast: var(--ion-color-success-contrast);
  --ion-color-earned-contrast-rgb: var(--ion-color-success-contrast-rgb);
  --ion-color-earned-shade: var(--ion-color-success-shade);
  --ion-color-earned-tint: var(--ion-color-success-tint);

  // Blue
  --ion-color-blue: #0f84cc;
  --ion-color-blue-rgb: 15, 132, 204;
  --ion-color-blue-contrast: #1D1E20;
  --ion-color-blue-contrast-rgb: 29, 30, 32;
  --ion-color-blue-shade: #0d74b4;
  --ion-color-blue-tint: #91cef3;

  // Red
  --ion-color-red: #db0045;
  --ion-color-red-rgb: 219, 0, 69;
  --ion-color-red-contrast: #1D1E20;
  --ion-color-red-contrast-rgb: 29, 30, 32;
  --ion-color-red-shade: #c1003d;
  --ion-color-red-tint: #2790d1;

  // Green
  --ion-color-green: var(--ion-color-success);
  --ion-color-green-rgb: var(--ion-color-success-rgb);
  --ion-color-green-contrast: var(--ion-color-success-contrast);
  --ion-color-green-contrast-rgb: var(--ion-color-success-contrast-rgb);
  --ion-color-green-shade: var(--ion-color-success-shade);
  --ion-color-green-tint: var(--ion-color-success-tint);

  // Yellow
  --ion-color-yellow: #fabf0f;
  --ion-color-yellow-rgb: 250, 191, 15;
  --ion-color-yellow-contrast: #1D1E20;
  --ion-color-yellow-contrast-rgb: 29, 30, 32;
  --ion-color-yellow-shade: #dca80d;
  --ion-color-yellow-tint: #fbc527;

  /*
  --ion-color-congrats-yellow: #b86e14;
  --ion-color-congrats-yellow-rgb: 184,110,20;
  --ion-color-congrats-yellow-contrast: #1D1E20;
  --ion-color-congrats-yellow-contrast-rgb: 29, 30, 32;
  --ion-color-congrats-yellow-shade: #a26112;
  --ion-color-congrats-yellow-tint: #bf7d2c;
*/
  // Purple
  --ion-color-purple: #6251d2;
  --ion-color-purple-rgb: 98, 81, 210;
  --ion-color-purple-contrast: #1D1E20;
  --ion-color-purple-contrast-rgb: 29, 30, 32;
  --ion-color-purple-shade: #5647b9;
  --ion-color-purple-tint: #7262d7;
  /*
    --ion-color-united-purple: #8610c1;
    --ion-color-united-purple-rgb: 134, 16, 193;
    --ion-color-united-purple-contrast: #1D1E20;
    --ion-color-united-purple-contrast-rgb: 29, 30, 32;
    --ion-color-united-purple-shade: #760eaa;
    --ion-color-united-purple-tint: #9228c7;
  */

  // Challenges
  --ion-color-challenges: #ffffff;
  --ion-color-challenges-rgb: 255, 255, 255;
  --ion-color-challenges-contrast: #1D1E20;
  --ion-color-challenges-contrast-rgb: 29, 30, 32;
  --ion-color-challenges-shade: #e0e0e0;
  --ion-color-challenges-tint: #ffffff;

  // Notification
  --ion-color-notification: #db0045;
  --ion-color-notification-rgb: 219, 0, 69;
  --ion-color-notification-contrast: #1D1E20;
  --ion-color-notification-contrast-rgb: 29, 30, 32;
  --ion-color-notification-shade: #c1003d;
  --ion-color-notification-tint: #df1a58;

  // Pexels
  --ion-color-pexels-contrast: #1D1E20;
  --ion-color-pexels-contrast-rgb: 29, 30, 32;


  --ion-background-color: var(--ion-color-background);
  --ion-backdrop-color: var(--ion-color-foreground);

  // ion-loading
  --ion-color-step-50: var(--ion-color-background);
  --ion-color-step-100: var(--ion-color-background);
  --ion-color-step-300: var(--ion-color-light);
  --ion-color-step-600: var(--ion-color-dark);
  --ion-color-step-850: var(--ion-color-foreground);

  --ion-text-color: var(--ion-color-foreground);
  --ion-text-color-rgb: var(--ion-color-foreground-rgb);
  --ion-text-color-rgb-contrast: var(--ion-color-foreground-rgb-contrast);
  --ion-text-color-shade: var(--ion-color-foreground-tint);
  --ion-text-color-tint: var(--ion-color-foreground-shade);

  // Material
  --palette-background-card-dark: var(--ion-color-background-rgb);
}
