/**
 * Definition for Client
 * Default theming of some Client UI components
 *   - award-card
 *   - category-card-item
 *   - sheet-modal
 *   - overlay-modal
 *   - sheet-overlay-modal
 *   - ranking-list
 */

$item-margin: .6rem;

@mixin award-card() {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .metadata {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .name {
    @include use-font("esbuild", "bold");
    font-size: 1rem;
    text-align: center;
  }

  .date {
    font-size: .9rem;
    margin-bottom: .5rem;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ion-button {
      height: 24px;
      font-size: 1rem;
      letter-spacing: normal;
    }
  }
}

@mixin category-card-item() {
  background-color: var(--card-background-color, var(--ion-color-background));
  transition: background-color ease-in-out .2s;
  padding: .1rem 1rem;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  .icon {
    width: 40px;
    min-width: 40px;

    ion-icon {
      width: 24px;
      height: 24px;
      min-width: 24px;
    }
  }

  .title, .metadata {
    display: flex;
    align-items: center;
  }

  .title {
    @include use-font("esbuild");
    color: var(--ion-color-foreground);
    padding: .3rem .5rem 0 1rem;
    font-size: 1.1rem;
    position: relative;
    left: -1rem;
    width: calc(100% + 1rem);
  }

  .metadata {
    color: var(--ion-color-medium-shade);
    font-size: var(--size-text-small);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: .3rem;

    .item {
      margin-right: .8rem;

      ion-icon, span {
        margin-right: .2rem;
      }

      &.flex {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span {
          @include use-font("esbuild", "bold");
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &.filter span {
        @include use-font("open-sans");
        font-size: 1rem;
      }
    }
  }

  &.view-mode-list {
    padding: .1rem .5rem;

    .title {
      font-size: .95rem;
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .icon {
      width: unset;
      margin-right: .5rem;
      display: flex;
      align-items: center;

      ion-icon {
        width: 18px;
        height: 18px;
        min-width: 18px;
      }
    }

    .metadata {
      padding: .3rem 0;
      font-size: 1.1rem;

      ion-icon {
        color: var(--ion-color-dark);
      }

      .item span {
        @include use-font("esbuild", "semibold");
      }
    }
  }
}

/**
 * Sheet Modal
 */
@mixin sheet-modal() {
  ion-title {
    @include use-font("esbuild", "bold");
  }

  ion-content {
    --padding-top: 1rem;
    --padding-bottom: 1rem;
    --padding-start: 1rem;
    --padding-end: 1rem;
    --background: var(--ion-color-background);
    --offset-bottom: auto !important;
    --overflow: auto;
    overflow: auto;

    @include standard-scrollbar();
  }

  ion-footer {
    box-shadow: none;

    ion-toolbar {
      text-align: center;
    }
  }
}


/**
 * Overlay modal (not fixed size)
 */
@mixin overlay-modal() {

  :host {
    --background: transparent;
    background: transparent;
    color: var(--ion-color-foreground);
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: .5rem;
  }

  .container {
    width: 100%;
    padding: 0;
    box-shadow: 0 8px 48px rgba(var(--ion-color-foreground-rgb), 0.4);
    max-width: var(--breakpoint-md);
    pointer-events: auto;
    background-color:  var(--ion-color-background);
    overflow: hidden;
    padding-bottom: var(--ion-safe-area-bottom);
    margin: auto;
    display: block;
    position: relative;
    border-radius: var(--border-radius-default, 1rem);
  }

  .header {
    border-radius: var(--border-radius-default, 1rem);
    overflow: hidden;

    ion-toolbar {
      --background: transparent;
    }

    ion-title {
      text-align: center;
      @include use-font("esbuild", "semibold");
    }
  }

  .content {
    padding: .5rem;
    max-height: calc(90vh - 2rem - var(--ion-safe-area-top));
    overflow-y: auto;
    @include standard-scrollbar();
  }

  .footer {
    text-align: center;
    padding: .5rem 1rem 1rem 1rem;

    ion-button {
      --border-radius: var(--border-radius-buttons);
      --padding-end: 2rem;
      --padding-start: 2rem;
    }
  }
}

/**
 * Sheet overlay modal
 */
@mixin sheet-overlay-modal() {
  @include sheet-modal();

  .container {
    margin-top: 1.5rem;
  }

  @include breakpoint-min(md) {
    @include overlay-modal();

    .container {
      max-width: 600px;
      max-height: 600px;
    }
  }
}


/**
 * Ranking List
 */
@mixin ranking-list() {
  width: 100%;
  position: relative;
  max-height: 450px;
  overflow-y: auto;
  margin-top: .8rem;
  @include standard-scrollbar();

  &.no-scroll {
    max-height: unset;
  }

  .item {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    margin: .5rem 0;
    min-height: 40px;
    cursor: auto;

    .progression {
      height: 1rem;
      width: 1rem;
      margin-right: $item-margin;
      margin-left: $item-margin;
      align-self: center;
    }

    .position {
      width: 24px;
      position: relative;
      margin-right: $item-margin;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      .num {
        width: 24px;
        height: 24px;
        min-width: 24px;
        border: 1px solid var(--ion-color-medium);
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--ion-color-medium);
        font-family: var(--ion-font-family-open-sans);
        font-size: .9rem;
        border-radius: 50%;
        background-color: var(--ion-color-medium-contrast);

        &:after, &:before {
          content: '';
          height: calc((100% - 24px + 1rem) / 2);
          width: 1px;
          background-color: var(--ion-color-medium);
          position: absolute;
          left: 50%;
        }

        &:before {
          top: -.5rem;
        }

        &:after {
          bottom: -.5rem;
        }
      }
    }

    .avatar {
      height: 32px;
      width: 32px;
      min-width: 32px;
      margin-right: $item-margin;
      align-self: center;
    }

    .name {
      width: calc(100% - 1rem - 24px - 32px - (#{$item-margin} * 4));
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      h3 {
        font-size: 1rem;
        @include use-font("esbuild", "bold");
        margin: 0;
        padding: 0;
        text-align: left;
      }

      span {
        font-size: .8rem;
        @include use-font("esbuild", "bold");
        margin: 0;
        padding: 0;
        text-align: left;
      }

      .metadata {
        @include use-font("esbuild", "semibold");
        color: var(--ion-color-medium);
        font-size: 1rem;
        display: block;
        align-items: unset;
        margin-bottom: 0;
        width: 100%;
      }
    }

    &:first-child .position .num:before {
      content: none;
    }

    &:last-child .position .num:after {
      content: none;
    }

    &.title {
      color: var(--ion-color-dark);
      text-transform: uppercase;
      font-size: 1rem;
      @include use-font("esbuild", "bold");
      min-height: 25px;
    }

    &.user-position {
      background-color: var(--ion-color-foreground);
      padding: .5rem 0;

      .position .num {
        background-color: var(--ion-color-foreground-contrast);
        border-color: var(--ion-color-foreground-contrast);
        color: var(--ion-color-foreground);
        font-weight: 900;

        &:after, &:before {
          content: none;
        }
      }

      .name {
        h3, .metadata, span {
          color: var(--ion-color-foreground-contrast);
        }
      }

      ion-progress-bar {
        --ion-color-base: var(--ion-color-background) !important;
        --ion-color-base-rgb: var(--ion-color-background-rgb) !important;
      }

      @include breakpoint-min(sm) {
        border-radius: var(--border-radius-default, 1rem);
      }
    }

    &.clickable {
      cursor: pointer;
    }
  }
}
