/**
 * Default definition
 * Default theming of some UI components
 *   - default-form-input
 *   - default-primary-title
 *   - default-part-title
 *   - default-flex-value
 *   - default-metadata
 *   - default-card
 *   - default-period-list
 *   - default-mobile-padding
 *   - default-notification-badge-text
 *   - default-notification-badge-empty
 */

/**
 * Default input
 */
@mixin default-form-input() {
  margin: 0 1rem;
  border: 1px solid var(--ion-color-light);
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 12px;
  padding: 10px;
  width: calc(100% - 2rem);

  &.invalid {
    border: 1px solid var(--ion-color-danger);
  }
}

/**
 * Default title for page
 */
// Rounded title with primary background
@mixin default-primary-title() {
  @include standard-round-title();

  background-color: var(--ion-color-primary);
  @include use-font("esbuild", "bold");
  padding: .5rem 1rem;
  margin: .5rem 0 .8rem 0;
  font-size: 1.1rem;
}
@mixin default-part-title() {
  max-width: unset;
  font-size: 1rem;
  @include use-font("esbuild", "bold");
  color: var(--ion-color-foreground);
  margin: 0;
  line-height: 1rem;
  text-align: left;
  border-bottom: 1px solid var(--ion-color-light-shade);
  padding: 4px 0;
  margin-bottom: .5rem;
}
@mixin default-primary-part-title() {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  @include use-font("esbuild", "semibold");
  padding: .3rem 1rem;
  font-size: 1.1rem;
  text-align: center;
  position: relative;
  width: calc(100% + 2rem);
  left: -1rem;
  display: block;

  @include breakpoint-min(md) {
    border-radius: var(--border-radius-default, 1rem) var(--border-radius-default, 1rem) 0 0;
  }
}
// Default flex value disposition
@mixin default-flex-value() {
  @include use-font("esbuild", "bold");
  font-size: 1.4rem;
  margin: .5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: .5rem;
}

/**
 * Default metadata
 */
@mixin default-metadata() {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  .dates {
    background-color: var(--ion-color-dark);
    color: var(--ion-color-medium-contrast);
    height: 1rem;
    border-radius: var(--border-radius-dates, 1rem);
    padding: 0 .5rem;
    display: inline-block;
    font-family: var(--ion-font-family-open-sans);
    font-weight: 700;
    font-size: .7rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sep, .sep ion-icon {
    // height: 8px;
    width: 8px;
    line-height: 8px;
  }

  .sep {
    margin: 0 .3rem;
  }

  .visibility {
    // margin-left: .8rem;

    ion-icon {
      width: 12px;
      height: 12px;
    }
  }
}

/**
 * Theming default date
 */
@mixin default-date() {
  background-color: var(--ion-color-medium);
  color: var(--ion-color-medium-contrast);
  font-size: var(--size-10);
  height: var(--size-16);
  border-radius: 1rem;
  padding: 0 .5rem;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/**
 * Theming default card of platform
 */

@mixin default-card() {
  background-color: var(--ion-color-background);
  transition: background-color ease-in-out .2s;
  position: relative;
  padding: .5rem;

  &.clickable {
    background-color: var(--card-background-color, var(--ion-color-background));
    transition: background-color ease-in-out .2s;
    cursor: pointer;
  }

  &.view-light {
    border-radius: 0;
  }

  // Title of card
  .title {
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
    gap: .5rem;

    .icon {
      background-color: var(--ion-color-foreground);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ion-icon, .icon, app-icon-circle {
      width: 24px;
      height: 24px;
      min-width: 24px;
    }

    .name {
      @include use-font("esbuild", "bold");
      text-transform: uppercase;
      font-size: 1.2rem;
      flex-grow: 1;
      max-width: calc(100% - 24px - 1rem);
    }
  }

  // Metadata (dates...)
  .metadata {
    @include default-metadata();
  }

  .row {
    margin-bottom: .5rem;

    // Title of row line
    h1 {
      @include standard-round-title();

      background-color: var(--ion-color-medium);
      padding: 0 .8rem;
      margin: 0;
      @include use-font("esbuild", "bold");
      font-size: .85rem;
      height: 1.2rem;
      display: inline-block;
      line-height: 1.2rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.earned {
        background-color: var(--ion-color-earned);
      }
    }

    // Value of row
    .value {
      @include use-font("esbuild", "bold");
      font-size: 1.5rem;
      padding: 0 .5rem;

      small, .small {
        @include use-font("open-sans");
        font-size: .9rem;
      }

      .small {
        line-height: .8rem;
      }

      &.icon {
        display: flex;
        align-items: center;

        span, ion-icon {
          margin-right: .5rem;
        }
      }
    }

    &.earned, .earned {
      h1 {
        background-color: var(--ion-color-earned);
      }

      .value, .value ion-icon {
        color: var(--ion-color-earned);
      }
    }
  }

  @include breakpoint-min(sm) {
    padding: 1rem;
    border-radius: var(--border-radius-default, 1rem);
  }
}

/**
 * Default period list (Day, Month, Week...)
 */
@mixin default-period-list() {
  ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    li {
      color: var(--ion-color-medium);
      padding: .5rem;
      font-size: .9rem;
      @include use-font("esbuild", "semibold");

      &.selected {
        color: var(--ion-color-primary);
        @include use-font("esbuild", "bold");
      }

      &.clickable {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

/**
 * Default padding for mobile
 */
@mixin default-mobile-padding() {
  --padding-top: 1rem;
  --padding-bottom: 1rem;
  --padding-start: 1rem;
  --padding-end: 1rem
}

/**
 * Default notification text badge
 */

@mixin default-notification-badge-text() {
  background-color: var(--ion-color-notification);
  color: var(--ion-color-notification-contrast);
  font-family: var(--ion-font-family-open-sans), serif;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  font-size: 10px;
  line-height: 22px;
  font-weight: bold;
  position: absolute;
  top: -6px;
  right: -14px;
  z-index: 1;
  text-align: center;

  @include breakpoint-max(lg) {
    height: 1.3rem;
    width: 1.3rem;
    line-height: 1.3rem;
    font-size: .8rem;
  }
}

/**
 * Default notification empty badge
 */

@mixin default-notification-badge-empty() {
  background-color: var(--ion-color-notification);
  color: var(--ion-color-notification-contrast);
  border-radius: 50%;
  height: .65rem;
  width: .65rem;
  margin: .15rem .25rem;
  -webkit-animation: notificationPulseAnimation 2s ease-in-out infinite; /* Chrome, Safari, Opera */
  animation: notificationPulseAnimation 2s ease-in-out infinite;
}
