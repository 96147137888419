
/**
 * Definition for Dashboard
 * Default theming of some Dashboard UI components
 *   - dashboard-toolbar
 */

@mixin dashboard-toolbar() {
  @include standard-part-separator();
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  gap: .5rem;

  div.item {
    background-color: var(--ion-color-background);
    padding: .5rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: .5rem;

    .title {
      font-size: .9rem;
      @include use-font("esbuild", "semibold");
      color: var(--ion-color-medium);
    }

    .value {
      @include use-font("esbuild", "bold");
      font-size: 1.3rem;

      small {
        @include use-font("open-sans");
        font-size: .8rem;
      }
    }

    &.export-button {
      .with-label {
        display: none;
      }
    }

    ion-button.csv-generate app-icon-tooltip {
      position: relative;
      top: -.2rem;
    }
  }

  @include breakpoint-min(sm) {
    div.item {
      border-radius: var(--border-radius-default, 1rem);
      width: unset;
    }
  }

  @include breakpoint-min(lg) {
    justify-content: flex-start;

    div.item {
      order: 1;
      justify-content: flex-start;
    }
    .date-select {
      order: 2;
    }
    .export-button {
      order: 3;
    }
  }

}
