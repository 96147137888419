/**
 * Utils definition
 *   - trans-hide-show
 *   - util-set-ion-padding({size})
 *   - util-default-padding({breakpoint})
 */


/**
 * Transitions
 */
@mixin trans-hide-show() {
  opacity: 0;
  transition: opacity ease-in-out .3s;
}

/**
 * Utils
 */
@mixin util-set-ion-padding($val1: 0, $val2: 0) {
  --padding-bottom: #{$val1};
  --padding-top: #{$val1};
  @if $val2==0 {
    --padding-start: #{$val1};
    --padding-end: #{$val1};
  } @else {
    --padding-start: #{$val2};
    --padding-end: #{$val2};
  }
}


/**
 * Set default padding of content according breakpoint
 */
@mixin util-default-padding($breakpoint: md) {
  @if $breakpoint==sm {
    --padding-start: 0;
    --padding-end: 0;
  }
  @if $breakpoint==md {
    --padding-start: 1rem;
    --padding-end: 1rem;
  }
  @if $breakpoint==lg or $breakpoint==lx or $breakpoint==xl or $breakpoint==xxl {
    --padding-start: 1rem;
    --padding-end: 2rem;
  }
}
