@media print {
  .print {
    display: inherit !important;
  }

  .not-print {
    display: none !important;
  }

  ion-content {
    --padding-top: 1rem;
    --padding-bottom: 1rem;
    --padding-end: 1rem;
    --padding-start: 1rem;
  }
}


