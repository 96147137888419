/**
 * Utils definition
 *   - use-font({family}, {theme})
 *   - font-esbuild({weight}, {style})
 */

/**
 * Use font with family and theme configuration
 *   - family: esbuild, open-sans
 *   - theme: normal, semibold, bold, italic, bold-italic
 */
@mixin use-font($family: "open-sans", $theme: "normal") {
  @if $family == "esbuild" {
    font-family: var(--ion-font-family-esbuild), serif;
  } @else if $family == "open-sans" {
    font-family: var(--ion-font-family-open-sans), serif;
  } @else {
    font-family: var(--ion-font-family), serif;
  }
  @if $theme == "normal" {
    font-weight: 400;
    font-style: normal;
  } @else if $theme == "semibold" {
    font-weight: 600;
    font-style: normal;
  } @else if $theme == "bold" {
    font-weight: 800;
    font-style: normal;
  } @else if $theme == "italic" {
    font-weight: 400;
    font-style: italic;
  } @else if $theme == "bold-italic" {
    font-weight: 800;
    font-style: italic;
  }
}


/**
 * Set ESBuild font with weight and style configuration
 */
@mixin font-esbuild($weight: 400, $style: normal) {
  font-family: var(--ion-font-family-esbuild), serif;
  font-weight: $weight;
  font-style: $style;
}
