/**
 * Common DOM elements styles of Design System
 */

// Main elements
html, body {
  font-size: 16px;
  @include use-font("open-sans");
  font-weight: 600;
  margin: 0;
  height: 100%;

  --ion-text-color: var(--ion-color-foreground);
}

// Titles
h1, h2, h3, h4, h5, h6 {
  @include use-font("esbuild", "bold");
  letter-spacing: normal;
  margin: 0;
  padding: 0;
}

// DS @HEADING/H3
h1 {
  font-size: var(--size-26);
  line-height: var(--size-38);
}

h2 {
  font-size: var(--size-24);
  line-height: var(--size-32);
}

h3 {
  font-size: var(--size-22);
  line-height: var(--size-30);
}

// DS @HEADING/H4
h4 {
  font-size: var(--size-20);
  line-height: var(--size-28);
}

h5 {
  font-size: var(--size-18);
  line-height: var(--size-24);
}

h6 {
  font-size: var(--size-16);
  line-height: var(--size-22);
}


// Texts
strong {
  @include use-font("esbuild", "bold");
}

a.mention {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}


// Forms
input, textarea, select, button {
  color: var(--ion-color-dark);
  background-color: var(--ion-color-light);

  &::placeholder {
    color: var(--ion-color-medium);
  }

  &:focus {
    outline-color: var(--ion-color-primary);
  }
}

.form-field {
  input, textarea {
    @include standard-form-input-round();

    width: 100%;
  }

  textarea {
    border-radius: var(--border-radius-default, 1rem);
  }

  .invalid-feedback {
    color: var(--ion-color-danger);
    width: 100%;
    text-align: left;
    font-size: 0.95rem;
    margin-bottom: 0;
    margin-top: .25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 0;
    transition: 400ms max-height ease-in-out, 200ms margin-bottom ease-in-out;
    overflow: hidden;

    &:before {
      content: "";
      background: url("/assets/icons/utils/alert.svg") no-repeat center center;
      background-size: 1.1rem 1.1rem;
      display: block;
      height: 1.1rem;
      width: 1.1rem;
      margin-right: .5rem;
    }

    &.visible {
      max-height: 3rem;
      margin-bottom: 1rem;
    }
  }

}

mat-select.default-primary-select {
  background-color: var(--ion-color-primary);
  min-height: 32px;
  border-radius: 1rem;
  line-height: 32px;
  padding: 0 1rem;

  .mat-select-value, .mat-select-arrow {
    color: var(--ion-color-primary-contrast) !important;
    text-transform: uppercase;
    font-size: .9rem;
    @include use-font("esbuild", "semibold");
  }
}

// Scrollbar
div, main, textarea, ul, ion-content::part(scroll) {

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--ion-color-light);
    border-radius: 5px;
  }
}

.scroll-responsive {
  overflow-y: auto;
}