
.NgxEditor {
  color: inherit !important;
}

.NgxEditor__MenuItem.NgxEditor__MenuItem--Active {
  background-color: var(--ion-color-background) !important;
}

.NgxEditor__Popup {
  background-color: var(--ion-color-background) !important;
  border: 1px solid var(--ion-color-medium) !important;
  border-radius: .7rem !important;

  .NgxEditor__Popup--FormGroup {
    input {
      @include standard-form-input-round();

      padding: .3rem .8rem !important;
    }
  }

  // Hide openInNewTab option
  .NgxEditor__Popup--Form .NgxEditor__Popup--FormGroup:nth-child(3) {
    display: none;
  }

  button[type="submit"] {
    border-radius: var(--border-radius-inputs, 1rem);
    background-color: var(--ion-color-medium);
    color: var(--ion-color-medium-contrast);
    padding: .4rem .8rem .3rem;

    &:hover {
      background-color: var(--ion-color-medium-shade);
    }
  }
}