
swiper {
  height: 100%;
  --swiper-pagination-color: var(--ion-color-primary);
}

.swiper-slide {
  display: flex;
  position: relative;
  flex-shrink: 0;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;

  .slide {
    width: 100%;
    height: 100%;
  }
}

.swiper-button-prev, .swiper-button-next {
  color: var(--ion-color-primary);
  border: 2px solid var(--ion-color-primary);
  border-radius: 50%;
  width: 44px;
  height: 44px;

  &:after {
    font-size: 30px;
    font-weight: bold;
  }
}
