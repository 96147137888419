@use '../../node_modules/@angular/material/index' as mat;

$mat-css-dark-theme-selector: '.dark-theme';
$mat-css-light-theme-selector: '.light-theme';

// @import '@angular/material/theming';
// Not use classic material initialization - see below
@import '../../node_modules/angular-material-css-vars/src/lib/main';
// @import "variables";
// Plus imports for other components in your app.


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
//@include mat-core();

// Palettes
/* For use in src/lib/core/theming/_palette.scss */
// @doc Generator -> http://mcg.mbitson.com/
$md-primary: (
    50 : #e0e0e0,
    100 : #b3b3b3,
    200 : #808080,
    300 : #4d4d4d,
    400 : #262626,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #a6a6a6,
    A200 : #8c8c8c,
    A400 : #737373,
    A700 : #666666,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-secondary: (
    50 : #e0e0e0,
    100 : #b3b3b3,
    200 : #808080,
    300 : #4d4d4d,
    400 : #262626,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #a6a6a6,
    A200 : #8c8c8c,
    A400 : #737373,
    A700 : #666666,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-tertiary: (
    50 : #e0e0e0,
    100 : #b3b3b3,
    200 : #808080,
    300 : #4d4d4d,
    400 : #262626,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #a6a6a6,
    A200 : #8c8c8c,
    A400 : #737373,
    A700 : #666666,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette($md-primary);
$app-accent: mat.define-palette($md-secondary, A200, A100, A400);

// Create the theme object. A theme csonsists of configurations for individual
// theming systems such as `color` or `typography`.
// $app-theme: mat-light-theme($app-primary, $app-accent);
// Not use classic material initialization - see below

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include angular-material-theme($app-theme);
// Not use classic material initialization - see below


// Initialize material through https://github.com/johannesjo/angular-material-css-vars
// In order to use dynamic theme change
@include init-material-css-vars();

@include mat-css-set-palette-defaults($app-primary, 'primary');
@include mat-css-set-palette-defaults($app-accent, 'accent');


// Typography
$typography: mat.define-typography-config(
    $font-family: var(--ion-font-family-open-sans),
);
@include mat.all-component-typographies($typography);


$variants: 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, A100, A200, A400, A700;
$variants-contrast: 50, 100, 200, 300, 400, 500, 600, 700, 800, 900;

// Build material variable for default theme
:root {
  $contrast-primary: map-get($app-primary, 'contrast');
  $contrast-accent: map-get($app-accent, 'contrast');

  @each $variant in $variants {
    --palette-primary-#{$variant}: #{_mat-css-hex-to-rgb(map-get($app-primary, $variant))};
  }
  @each $variant in $variants {
    --palette-accent-#{$variant}: #{_mat-css-hex-to-rgb(map-get($app-accent, $variant))};
  }
  @each $variant in $variants-contrast {
    --palette-primary-contrast-#{$variant}: #{_mat-css-hex-to-rgb(map-get($contrast-primary, $variant))};
  }
  @each $variant in $variants-contrast {
    --palette-accent-contrast-#{$variant}: #{_mat-css-hex-to-rgb(map-get($contrast-accent, $variant))};
  }
}